import "../../Assests/Styles/Common.css";
import { useTranslation } from "react-i18next";
import styles from "../SignUp/SignUp.module.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import "react-datepicker/dist/react-datepicker.css";
import { useForm, Controller } from "react-hook-form";
import { Form, Modal, NavLink } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { sanitizeHtmlTags } from "../../utils/SanitizeHtmlTag";
import { useEffect, useState } from "react";
import RelationshipModal from "./RelationshipModal";
import { IoMdArrowDropdown } from "react-icons/io";
import RelationshipModal2 from "./RelationshipModal2";
import RelationshipModal3 from "./RelationshipModal3";
import { useDispatch, useSelector } from "react-redux";
import {
  setSignupData,
  clearSignupData,
  signUp,
  resetSwitchTo,
  setSocialUserData,
} from "../../store/slices/userSlice";
import { STATUS_MSG } from "../../utils/StatusMsg";
import { STATUS_CODES } from "../../utils/StatusCode";
import Toster from "../../utils/Toster";
import { SIGNUP_TYPE } from "../../utils/Constants";
import { useNavigate } from "react-router-dom";
import mime from "mime";
import "../../Assests/Styles/Common.css";
import Loader from "../../utils/Loader/Loader";
import emergencyContact from "../../Assests/Images/emergency_contact_ico.svg";

function SignUpStepFourth({ setSwitchTo, setPreviousValue }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [confirmNavigation, setConfirmNavigation] = useState(false);

  const { signupData, isLoading, socialUserData, masterList } = useSelector(
    (state) => state.user
  );
  const [showThirdContact, setShowThirdContact] = useState(false);
  const [relationshipModalOpen, setRelationshipModalOpen] = useState(false);
  const [relationshipModalOpen2, setRelationshipModalOpen2] = useState(false);
  const [relationshipModalOpen3, setRelationshipModalOpen3] = useState(false);
  const [selectedRelationShip1, setSelectedRelationship1] = useState("");
  const [selectedRelationShip2, setSelectedRelationship2] = useState("");
  const [selectedRelationShip3, setSelectedRelationship3] = useState("");
  const [phoneNo1, setPhoneNo1] = useState(signupData.name);
  const [phoneNo2, setPhoneNo2] = useState("");
  const [phoneNo3, setPhoneNo3] = useState("");
  const [homeNo1, setHomeNo1] = useState("");
  const [homeNo2, setHomeNo2] = useState("");
  const [homeNo3, setHomeNo3] = useState("");
  const [phoneDialCode1, setPhoneDialCode1] = useState("");
  const [phoneDialCode2, setPhoneDialCode2] = useState("");
  const [phoneDialCode3, setPhoneDialCode3] = useState("");
  const [homeDialCode1, setHomeDialCode1] = useState("");
  const [homeDialCode2, setHomeDialCode2] = useState("");
  const [homeDialCode3, setHomeDialCode3] = useState("");
  const [phoneCountryCode1, setPhoneCountryCode1] = useState("");
  const [phoneCountryCode2, setPhoneCountryCode2] = useState("");
  const [phoneCountryCode3, setPhoneCountryCode3] = useState("");
  const [homeCountryCode1, setHomeCountryCode1] = useState("");
  const [homeCountryCode2, setHomeCountryCode2] = useState("");
  const [homeCountryCode3, setHomeCountryCode3] = useState("");
  const [isDefault, setIsDefault] = useState(1);
  const [showAlertModal, setShowAlertModal] = useState(false);

  //========= Add a state variable for loading state======
  const [loading, setLoading] = useState(false);

  // Function to handle user's choice from the alert modal
  const handleAlertModalChoice = (choice) => {
    if (choice === "ok") {
      const contactData = [
        {
          name: getValues("name1"),
          relation: selectedRelationShip1 && selectedRelationShip1.id,
          dail_code_1: phoneDialCode1,
          phone_num_1: phoneNo1,
          phone_num_2: homeNo1,
          phoneCountryCode1: phoneCountryCode1,
          dail_code_2: homeDialCode1,
          homeCountryCode1: homeCountryCode1,
          is_default: isDefault == 1 ? 1 : 0,
          showThirdContact: showThirdContact,
        },
        {
          name: getValues("name2"),
          relation: selectedRelationShip2 && selectedRelationShip2.id,
          dail_code_1: phoneDialCode2,
          phone_num_1: phoneNo2,
          phone_num_2: homeNo2,
          phoneCountryCode2: phoneCountryCode2,
          dail_code_2: homeDialCode2,
          homeCountryCode2: homeCountryCode2,
          is_default: isDefault == 2 ? 1 : 0,
          showThirdContact: showThirdContact,
        },
      ];

      if (getValues("name3")) {
        contactData.push({
          name: getValues("name3"),
          relation: selectedRelationShip3 && selectedRelationShip3.id,
          dail_code_1: phoneDialCode3,
          phone_num_1: phoneNo3,
          phone_num_2: homeNo3,
          phoneCountryCode3: phoneCountryCode3,
          dail_code_2: homeDialCode3,
          homeCountryCode3: homeCountryCode3,
          is_default: isDefault == 3 ? 1 : 0,
          showThirdContact: showThirdContact,
        });
      }

      dispatch(setSignupData({ contactData: contactData }));
      // If user clicks OK, navigate to the previous page
      setSwitchTo(2);
      setPreviousValue(true);
    }
    // Close the alert modal in both cases
    setShowAlertModal(false);
  };

  //======= function for show this form data prefilled==========
  useEffect(() => {
    if (signupData && signupData.contactData) {
      const contactData = signupData.contactData;
      const contactRelation =
        masterList.contact_relation && masterList.contact_relation.length > 0
          ? masterList.contact_relation
          : [];

      contactData.map((item, index) => {
        if (index == 0) {
          setValue("name1", item.name);
          if (item.relation) {
            setSelectedRelationship1({
              id: item.relation,
              value_name:
                contactRelation.length > 0 &&
                contactRelation.find((x) => x.id === item.relation).value_name,
            });
            setValue(
              "relationship1",
              contactRelation.length > 0 &&
                contactRelation.find((x) => x.id === item.relation).value_name
            );
          }

          setValue("phoneNumber1", item.phone_num_1);
          setValue("home1", item.phone_num_2);
          setPhoneNo1(item.phone_num_1);
          setHomeNo1(item.phone_num_2);
          setPhoneDialCode1(item.dail_code_1);
          setHomeDialCode1(item.dail_code_2);
          if (item.is_default == 1) {
            setIsDefault(item.is_default == 1 ? 1 : 0);
          }

          setShowThirdContact(item.showThirdContact);
        }
        if (index == 1) {
          setValue("name2", item.name);
          if (item.relation) {
            setSelectedRelationship2({
              id: item.relation,
              value_name:
                contactRelation.length > 0 &&
                contactRelation.find((x) => x.id === item.relation).value_name,
            });
            setValue(
              "relationship2",
              contactRelation.length > 0 &&
                contactRelation.find((x) => x.id === item.relation).value_name
            );
          }

          setValue("phoneNumber2", item.phone_num_1);
          setValue("home2", item.phone_num_2);

          setPhoneNo2(item.phone_num_1);
          setHomeNo2(item.phone_num_2);
          setPhoneDialCode2(item.dail_code_1);
          setHomeDialCode2(item.dail_code_2);
          if (item.is_default == 1) {
            setIsDefault(item.is_default == 1 ? 2 : 0);
          }
        }
        if (index == 2) {
          setValue("name3", item.name);
          if (item.relation) {
            setSelectedRelationship3({
              id: item.relation,
              value_name:
                contactRelation.length > 0 &&
                contactRelation.find((x) => x.id === item.relation).value_name,
            });
            setValue(
              "relationship3",
              contactRelation.length > 0 &&
                contactRelation.find((x) => x.id === item.relation).value_name
            );
          }

          setValue("phoneNumber3", item.phone_num_1);
          setValue("home3", item.phone_num_2);
          setPhoneNo3(item.phone_num_1);
          setHomeNo3(item.phone_num_2);
          setPhoneDialCode3(item.dail_code_1);
          setHomeDialCode3(item.dail_code_2);
          if (item.is_default == 1) {
            setIsDefault(item.is_default == 1 ? 3 : 0);
          }
        }
      });
    }
  }, []);

  //========== Add a function to handle setting default contact=========
  const handleSetDefaultContact = (contactNumber) => {
    setIsDefault(contactNumber);
  };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm();

  //---- function for formate image ------------
  function b64toBlob(cropData, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;
    var byteCharacters = window.atob(cropData); //decode string
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  function getRandom() {
    return Math.floor(Math.random() * 9000000000) + 1000000000;
  }

  // function for remove extra + in dial code
  function removeExtraPlus(inputString) {
    let newStr = "+" + inputString;
    return newStr.replace(/\++/g, "+");
  }

  //-------- function for final signup api--------
  const onSubmit = async (formdata) => {
    const contactData = [
      {
        name: formdata.name1,
        relation: selectedRelationShip1 && selectedRelationShip1.id,
        dail_code_1: removeExtraPlus(phoneDialCode1),
        phone_num_1: phoneNo1,
        phone_num_2: homeNo1,
        phoneCountryCode1: phoneCountryCode1,
        dail_code_2: removeExtraPlus(homeDialCode1),
        homeCountryCode1: homeCountryCode1,
        is_default: isDefault == 1 ? 1 : 0,
        showThirdContact: showThirdContact,
      },
      {
        name: formdata.name2,
        relation: selectedRelationShip2 && selectedRelationShip2.id,
        dail_code_1: removeExtraPlus(phoneDialCode2),
        phone_num_1: phoneNo2,
        phone_num_2: homeNo2,
        phoneCountryCode2: phoneCountryCode2,
        dail_code_2: removeExtraPlus(homeDialCode2),
        homeCountryCode2: homeCountryCode2,
        is_default: isDefault == 2 ? 1 : 0,
        showThirdContact: showThirdContact,
      },
    ];
    if (formdata.name3) {
      contactData.push({
        name: formdata.name3,
        relation: selectedRelationShip3 && selectedRelationShip3.id,
        dail_code_1: removeExtraPlus(phoneDialCode3),
        phone_num_1: phoneNo3,
        phone_num_2: homeNo3,
        phoneCountryCode3: phoneCountryCode3,
        dail_code_2: removeExtraPlus(homeDialCode3),
        homeCountryCode3: homeCountryCode3,
        is_default: isDefault == 3 ? 1 : 0,
        showThirdContact: showThirdContact,
      });
    }
    const secuAnsData = [];
    if (signupData.securityQuestion1 && signupData.securityQuestion1.id) {
      secuAnsData.push({
        sq: signupData.securityQuestion1.id,
        ans: signupData.securityAnswer1,
      });
    }

    if (signupData.securityQuestion2 && signupData.securityQuestion2.id) {
      secuAnsData.push({
        sq: signupData.securityQuestion2.id,
        ans: signupData.securityAnswer2,
      });
    }

    if (signupData.securityQuestion3 && signupData.securityQuestion3.id) {
      secuAnsData.push({
        sq: signupData.securityQuestion3.id,
        ans: signupData.securityAnswer3,
      });
    }

    let formDataVehicle = "";
    if (signupData.is_skip !== undefined && signupData.is_skip === 0) {
      var blockCrop =
        signupData && signupData.selectedFile
          ? signupData.selectedFile.split(";")
          : [];
      if (blockCrop.length < 2) {
        console.error("Invalid selected file format");
        return; // Or handle the error accordingly
      }
      // Get the content type of the image
      var cropContentTypes = blockCrop[0].split(":")[1]; // In this case "image/png"
      // get the real base64 content of the file
      var blockRealData = blockCrop[1].split(",")[1];
      var cropBlobImg = await b64toBlob(blockRealData, cropContentTypes);
      formDataVehicle = new FormData();
      formDataVehicle.append(
        "file",
        cropBlobImg,
        getRandom() + "." + mime.getExtension(cropBlobImg.type)
      );
    }

    let requestData = new FormData();
    if (signupData) {
      requestData.append(
        "d_signup_type",
        socialUserData && socialUserData.id
          ? SIGNUP_TYPE.SOCIAL_SIGNUP
          : SIGNUP_TYPE.NORMAL_SIGNUP
      );
      requestData.append("d_name", signupData.name ? signupData.name : "");
      requestData.append("d_email", signupData.email ? signupData.email : "");
      requestData.append(
        "d_password",
        signupData.password ? signupData.password : ""
      );

      if (socialUserData && socialUserData.id) {
        requestData.append("d_social_id", socialUserData && socialUserData.id);
        requestData.append(
          "d_social_type",
          socialUserData && socialUserData.socialType
        );
      }
      requestData.append(
        "d_cnf_password",
        signupData.confirmPassword ? signupData.confirmPassword : ""
      );

      requestData.append(
        "d_dial_code",
        signupData.phoneDialCode
          ? removeExtraPlus(signupData.phoneDialCode)
          : ""
      );
      requestData.append(
        "d_phone_country_code",
        signupData.phoneCountryCode ? signupData.phoneCountryCode : ""
      );
      requestData.append(
        "d_phone_num",
        signupData.driverPhoneNo ? signupData.driverPhoneNo : ""
      );
      requestData.append(
        "d_home_dial_code",
        signupData.homeDialCode ? removeExtraPlus(signupData.homeDialCode) : ""
      );

      requestData.append("d_home_country_code", signupData.homeCountryCode);
      requestData.append(
        "d_home_num",
        signupData.driverHomeNo ? signupData.driverHomeNo : ""
      );
      requestData.append(
        "d_address",
        signupData.address ? signupData.address : ""
      );
      requestData.append(
        "d_nat_id",
        signupData.nationalId ? signupData.nationalId : ""
      );
      requestData.append("d_pin", signupData.pin ? signupData.pin : "");
      requestData.append("d_birthday", signupData.dob ? signupData.dob : "");
      requestData.append(
        "d_blood_id",
        signupData && signupData.blood_data && signupData.blood_data.id
      );
      requestData.append(
        "d_health_issue_id",
        signupData &&
          signupData.selectedHealthIssue &&
          signupData.selectedHealthIssue.toString()
      );
      requestData.append(
        "d_other_health_issue",
        signupData && signupData.otherInputValue
          ? signupData.otherInputValue
          : ""
      );
      requestData.append("d_company_nm", signupData.company);
      if (signupData.is_skip !== undefined && signupData.is_skip === 0) {
        requestData.append("d_veh_image", formDataVehicle.get("file"));
        requestData.append("d_license_plate_no", signupData.licenseNumber);
        requestData.append("d_veh_model", signupData.modalNumber);
        requestData.append("d_veh_series_no", signupData.serialnumber);
        requestData.append(
          "d_veh_colour_id",
          signupData.bike_color && signupData.bike_color.id
        );
        requestData.append(
          "d_veh_year",
          signupData && signupData.yearOfBike
            ? new Date(signupData.yearOfBike).getFullYear()
            : ""
        );
        requestData.append(
          "d_scanner_json",
          JSON.stringify({
            device_id: "1",
            serial_no: "",
            version: 48,
            manufacturer: "USBANA2022",
            firmware_id: 2186,
          })
        );
      }

      requestData.append("d_secu_ans_json", JSON.stringify(secuAnsData));
      requestData.append("d_e_contact_json", JSON.stringify(contactData));

      let isSkippedValue = 0;
      if (signupData.is_skip && signupData.is_skip == 1) {
        isSkippedValue = 1;
      }
      requestData.append("is_skipped", isSkippedValue);
    }
    setLoading(true);
    await dispatch(signUp(requestData)).then((responsejson) => {
      const response = responsejson.payload;
      const ResponseCode = STATUS_MSG[response.data.code];
      if (response.code === STATUS_CODES.SUCCESS) {
        dispatch(clearSignupData());
        dispatch(resetSwitchTo());
        dispatch(setSocialUserData({}));
        navigate("/login");
        setLoading(false);
      } else if (response.data.code === STATUS_CODES.USER_NOT_FOUND) {
        Toster(t("USER_NOT_FOUND"), "error");
        setLoading(false);
      } else if (response.data.code === STATUS_CODES.NATIONAL_ID_NUMBER_EXIST) {
        Toster(t("NATIONAL_ID_NUMBER_EXIST"), "error");
        setLoading(false);
      } else if (response.data.code === STATUS_CODES.VALIDATION_ERROR) {
        Toster(response.data.message, "error");
        setLoading(false);
      } else {
        Toster(t(ResponseCode), "error");
        setLoading(false);
      }
    });
  };

  //======= disable space on key press input password field========
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  //======== function for apply validation in phoneNumber field=========
  const validatePhoneNumber1 = (value) => {
    if (!value) {
      return `${t("CONTACT_NUMBER_REQUIRED")}`;
    }
    const phoneNo1 = value.replace(/\D/g, "");
    if (phoneNo1.length <= 8) {
      return `${t("PHONE_NUMBER_LENGTH_ERROR")}`;
    }
    return true;
  };
  const validatePhoneNumber2 = (value) => {
    if (!value) {
      return `${t("CONTACT_NUMBER_REQUIRED")}`;
    }
    const phoneNo2 = value.replace(/\D/g, "");
    if (phoneNo2.length <= 8) {
      return `${t("PHONE_NUMBER_LENGTH_ERROR")}`;
    }
    return true;
  };

  const validatePhoneNumber3 = (value) => {
    if (!value) {
      return `${t("CONTACT_NUMBER_REQUIRED")}`;
    }
    const phoneNo3 = value.replace(/\D/g, "");
    if (phoneNo3.length <= 8) {
      return `${t("PHONE_NUMBER_LENGTH_ERROR")}`;
    }
    return true;
  };

  //====== function for apply validation in homeNumber field=========
  const validateHomeNumber1 = (value) => {
    if (!value) {
      return `${t("HOME_NUMBER_REQUIRED")}`;
    }
    const homeNo1 = value.replace(/\D/g, "");
    if (homeNo1.length <= 8) {
      return `${t("HOME_NUMBER_LENGTH_ERROR")}`;
    }
    return true;
  };
  const validateHomeNumber2 = (value) => {
    if (!value) {
      return `${t("HOME_NUMBER_REQUIRED")}`;
    }
    const homeNo2 = value.replace(/\D/g, "");
    if (homeNo2.length <= 8) {
      return `${t("HOME_NUMBER_LENGTH_ERROR")}`;
    }
    return true;
  };
  const validateHomeNumber3 = (value) => {
    if (!value) {
      return `${t("HOME_NUMBER_REQUIRED")}`;
    }
    const homeNo3 = value.replace(/\D/g, "");
    if (homeNo3.length <= 8) {
      return `${t("HOME_NUMBER_LENGTH_ERROR")}`;
    }
    return true;
  };

  // Add confirmation for navigation away from the form
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    const handleBackButton = (event) => {
      setShowAlertModal(true);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [showAlertModal]);

  return (
    <div className={styles.signpWrapper}>
      {isLoading ? <Loader /> : ""}
      <div className={styles.tittle}>
        <div className={styles.backIcon}>
          <IoMdArrowRoundBack onClick={() => setShowAlertModal(true)} />
        </div>
        <Modal show={showAlertModal} onHide={() => setShowAlertModal(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className={styles.goBack}>{t("BACK_ALERT_TEXT")}</div>
          </Modal.Body>
          <div className={styles.Btns}>
            <button
              className={styles.cancelBtn}
              onClick={() => handleAlertModalChoice("cancel")}
            >
              {t("CANCEL")}
            </button>
            <button
              className={styles.deleteBtn}
              onClick={() => handleAlertModalChoice("ok")}
            >
              {t("OK")}
            </button>
          </div>
        </Modal>
        <div className={styles.signUpTitle}>
          <p>
            {t("EMERGENCY_CONTACT_TITLE")} <img src={emergencyContact}></img>
          </p>
        </div>
        <p className={styles.stepCount}>
          4 <span>/4</span>
        </p>
      </div>

      <div className={styles.contactStep}>
        <div className={styles.emergencyContact}>
          <div className={styles.contactText}>
            <h5>{t("CONTACT1_TITLE")}</h5>
          </div>
          <div
            onClick={() => handleSetDefaultContact(1)}
            className={`defaultText ${
              isDefault === 1 ? "activeContactDefult" : ""
            }`}
          >
            <NavLink>{t("SET_DEFAULT_CONTACT")}</NavLink>
          </div>
        </div>
        <div className={styles.inputForm}>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.formSection}
          >
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("NAME_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("NAME_PLACEHOLDER_TEXT")}`}
                type="text"
                {...register("name1", {
                  onChange: (e) => {
                    handleKeyPress(e);
                  },
                  required: {
                    value: true,
                    message: `${t("NAME_REQUIRED_MESSAGE")}`,
                  },
                  ...sanitizeHtmlTags(),
                })}
              />

              {errors.name1 && (
                <span className="errorMsg">{errors.name1.message}</span>
              )}
            </Form.Group>

            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("RELATIONSHIP_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={t("RELATIONSHIP_PLACEHOLDER_TEXT")}
                type="text"
                readOnly
                onClick={() => setRelationshipModalOpen(true)}
                {...register("relationship1", {
                  required: {
                    value: true,
                    message: `${t("RELATIONSHIP_REQUIRED_MESSAGE")}`,
                  },
                })}
              />
              <IoMdArrowDropdown
                className="dropDown"
                onClick={() => {
                  setRelationshipModalOpen(true);
                }}
              />

              {errors.relationship1 && (
                <span className="errorMsg">{errors.relationship1.message}</span>
              )}
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <div className="phone-input-container">
                <Controller
                  control={control}
                  name="phoneNumber1"
                  render={({ field }) => (
                    <PhoneInput
                      specialLabel={t("PHONE_INPUT_LABEL")}
                      placeholder="Phone Number1"
                      disableDropdown={true}
                      countryCodeEditable={false}
                      onlyCountries={["do"]}
                      inputProps={{ maxLength: 15 }}
                      country={"do"}
                      value={
                        (phoneDialCode1 && phoneDialCode1.toString()) +
                        (phoneNo1 && phoneNo1.toString())
                      }
                      onChange={(value, country) => {
                        let dialCode = country.dialCode;
                        let phone = value.slice(dialCode.length, value.length);
                        setPhoneCountryCode1(country.countryCode);
                        setPhoneDialCode1(dialCode);
                        setPhoneNo1(phone);
                        field.onChange(value);
                        clearErrors("");
                      }}
                      inputExtraProps={{
                        style: {
                          position: "relative", // Ensure relative positioning for the container
                        },
                      }}
                      dropdownClass={styles.customDropdown} // Apply custom dropdown styles
                    />
                  )}
                  rules={{ validate: validatePhoneNumber1 }}
                />
                {errors.phoneNumber1 && (
                  <span className="errorMsg">
                    {errors.phoneNumber1.message}
                  </span>
                )}
              </div>
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <div className="phone-input-container">
                <Controller
                  control={control}
                  name="home1"
                  render={({ field }) => (
                    <PhoneInput
                      specialLabel={t("HOME_INPUT_LABEL")}
                      placeholder={`${t("HOME_NUMBER_PLACEHOLDER")}`}
                      disableDropdown={true}
                      countryCodeEditable={false}
                      onlyCountries={["do"]}
                      inputProps={{ maxLength: 15 }}
                      country={"do"}
                      value={
                        (homeDialCode1 && homeDialCode1.toString()) +
                        (homeNo1 && homeNo1.toString())
                      }
                      onChange={(value, country) => {
                        let dialCode = country.dialCode;
                        let home = value.slice(dialCode.length, value.length);
                        setHomeCountryCode1(country.countryCode);
                        setHomeDialCode1(dialCode);
                        setHomeNo1(home);
                        field.onChange(value);
                        clearErrors("");
                      }}
                      inputExtraProps={{
                        style: {
                          position: "relative", // Ensure relative positioning for the container
                        },
                      }}
                      dropdownClass={styles.customDropdown} // Apply custom dropdown styles
                    />
                  )}
                  rules={{ validate: validateHomeNumber1 }}
                />
                {errors.home1 && (
                  <span className="errorMsg">{errors.home1.message}</span>
                )}
              </div>
            </Form.Group>
          </Form>
        </div>
      </div>
      <div className={styles.contactStep}>
        <div className={styles.emergencyContact}>
          <div className={styles.contactText}>
            <h5>{t("CONTACT2_TITLE")}</h5>
          </div>
          <div
            onClick={() => handleSetDefaultContact(2)}
            className={`defaultText ${
              isDefault === 2 ? "activeContactDefult" : ""
            }`}
          >
            <NavLink>{t("SET_DEFAULT_CONTACT")}</NavLink>
          </div>
        </div>
        <div className={styles.inputForm}>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.formSection}
          >
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("NAME_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("NAME_PLACEHOLDER_TEXT")}`}
                type="text"
                {...register("name2", {
                  onChange: (e) => {
                    handleKeyPress(e);
                  },
                  required: {
                    value: true,
                    message: `${t("NAME_REQUIRED_MESSAGE")}`,
                  },
                  ...sanitizeHtmlTags(),
                })}
              />

              {errors.name2 && (
                <span className="errorMsg">{errors.name2.message}</span>
              )}
            </Form.Group>

            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("RELATIONSHIP_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={t("RELATIONSHIP_PLACEHOLDER_TEXT")}
                type="text"
                readOnly
                onClick={() => setRelationshipModalOpen2(true)}
                {...register("relationship2", {
                  required: {
                    value: true,
                    message: `${t("RELATIONSHIP_REQUIRED_MESSAGE")}`,
                  },
                })}
              />
              <IoMdArrowDropdown
                className="dropDown"
                onClick={() => {
                  setRelationshipModalOpen2(true);
                }}
              />

              {errors.relationship2 && (
                <span className="errorMsg">{errors.relationship2.message}</span>
              )}
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <div className="phone-input-container">
                <Controller
                  control={control}
                  name="phoneNumber2"
                  render={({ field }) => (
                    <PhoneInput
                      specialLabel={t("PHONE_INPUT_LABEL")}
                      placeholder={`${t("PHONE_NUMBER_PLACEHOLDER")}`}
                      disableDropdown={true}
                      countryCodeEditable={false}
                      onlyCountries={["do"]}
                      inputProps={{ maxLength: 15 }}
                      country={"do"}
                      value={
                        (phoneDialCode2 && phoneDialCode2.toString()) +
                        (phoneNo2 && phoneNo2.toString())
                      }
                      onChange={(value, country) => {
                        let dialCode = country.dialCode;
                        let phone = value.slice(dialCode.length, value.length);
                        setPhoneCountryCode2(country.countryCode);
                        setPhoneDialCode2(dialCode);
                        setPhoneNo2(phone);
                        field.onChange(value); // Update the form value
                        clearErrors("");
                      }}
                      inputExtraProps={{
                        style: {
                          position: "relative", // Ensure relative positioning for the container
                        },
                      }}
                      dropdownClass={styles.customDropdown} // Apply custom dropdown styles
                    />
                  )}
                  rules={{ validate: validatePhoneNumber2 }}
                />
                {errors.phoneNumber2 && (
                  <span className="errorMsg">
                    {errors.phoneNumber2.message}
                  </span>
                )}
                {/* {errors.phone && (
                  <span className="errorMsg">{errors.phone.message}</span>
                )} */}
              </div>
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <div className="phone-input-container">
                <Controller
                  control={control}
                  name="home2"
                  render={({ field }) => (
                    <PhoneInput
                      specialLabel={t("HOME_INPUT_LABEL")}
                      placeholder={`${t("PHONE_NUMBER_PLACEHOLDER")}`}
                      disableDropdown={true}
                      countryCodeEditable={false}
                      onlyCountries={["do"]}
                      inputProps={{ maxLength: 15 }}
                      country={"do"}
                      value={
                        (homeDialCode2 && homeDialCode2.toString()) +
                        (homeNo2 && homeNo2.toString())
                      }
                      onChange={(value, country) => {
                        let dialCode = country.dialCode;
                        let phone = value.slice(dialCode.length, value.length);
                        setHomeCountryCode2(country.countryCode);
                        setHomeDialCode2(dialCode);
                        setHomeNo2(phone);
                        field.onChange(value); // Update the form value
                        clearErrors("");
                      }}
                      inputExtraProps={{
                        style: {
                          position: "relative", // Ensure relative positioning for the container
                        },
                      }}
                      dropdownClass={styles.customDropdown} // Apply custom dropdown styles
                    />
                  )}
                  rules={{ validate: validateHomeNumber2 }}
                />
                {errors.home2 && (
                  <span className="errorMsg">{errors.home2.message}</span>
                )}
              </div>
            </Form.Group>
            {showThirdContact && (
              <>
                <div className={styles.emergencyContacts}>
                  <div className={styles.contactText}>
                    <h5>{t("CONTACT3_TITLE")}</h5>
                  </div>
                  <div
                    onClick={() => handleSetDefaultContact(3)}
                    className={`defaultText ${
                      isDefault === 3 ? "activeContactDefult" : ""
                    }`}
                  >
                    <NavLink>{t("SET_DEFAULT_CONTACT")}</NavLink>
                  </div>
                </div>
                <Form.Group className={styles.FormGroup}>
                  <Form.Label className={styles.FormLabel}>
                    {t("NAME_LABEL")}
                  </Form.Label>
                  <Form.Control
                    className={styles.FormInput}
                    placeholder={`${t("NAME_PLACEHOLDER_TEXT")}`}
                    type="text"
                    {...register("name3", {
                      onChange: (e) => {
                        handleKeyPress(e);
                      },
                      required: {
                        value: true,
                        message: `${t("NAME_REQUIRED_MESSAGE")}`,
                      },
                      ...sanitizeHtmlTags(),
                    })}
                  />

                  {errors.name3 && (
                    <span className="errorMsg">{errors.name3.message}</span>
                  )}
                </Form.Group>

                <Form.Group className={styles.FormGroup}>
                  <Form.Label className={styles.FormLabel}>
                    {t("RELATIONSHIP_LABEL")}
                  </Form.Label>
                  <Form.Control
                    className={styles.FormInput}
                    placeholder={t("RELATIONSHIP_PLACEHOLDER_TEXT")}
                    type="text"
                    readOnly
                    onClick={() => setRelationshipModalOpen3(true)}
                    {...register("relationship3", {
                      required: {
                        value: true,
                        message: `${t("RELATIONSHIP_REQUIRED_MESSAGE")}`,
                      },
                    })}
                  />
                  <IoMdArrowDropdown
                    className="dropDown"
                    onClick={() => {
                      setRelationshipModalOpen3(true);
                    }}
                  />

                  {errors.relationship3 && (
                    <span className="errorMsg">
                      {errors.relationship3.message}
                    </span>
                  )}
                </Form.Group>
                <Form.Group className={styles.FormGroup}>
                  <div className="phone-input-container">
                    <Controller
                      control={control}
                      name="phoneNumber3"
                      render={({ field }) => (
                        <PhoneInput
                          specialLabel={t("PHONE_INPUT_LABEL")}
                          placeholder={`${t("PHONE_NUMBER_PLACEHOLDER")}`}
                          disableDropdown={true}
                          countryCodeEditable={false}
                          onlyCountries={["do"]}
                          inputProps={{ maxLength: 15 }}
                          country={"do"}
                          value={
                            (phoneDialCode3 && phoneDialCode3.toString()) +
                            (phoneNo3 && phoneNo3.toString())
                          }
                          onChange={(value, country) => {
                            let dialCode = country.dialCode;
                            let phone = value.slice(
                              dialCode.length,
                              value.length
                            );
                            setPhoneCountryCode3(country.countryCode);
                            setPhoneDialCode3(dialCode);
                            setPhoneNo3(phone);
                            field.onChange(value); // Update the form value
                            clearErrors("");
                          }}
                          inputExtraProps={{
                            style: {
                              position: "relative", // Ensure relative positioning for the container
                            },
                          }}
                          dropdownClass={styles.customDropdown} // Apply custom dropdown styles
                        />
                      )}
                      rules={{ validate: validatePhoneNumber3 }}
                    />
                    {errors.phoneNumber3 && (
                      <span className="errorMsg">
                        {errors.phoneNumber3.message}
                      </span>
                    )}
                  </div>
                </Form.Group>
                <Form.Group className={styles.FormGroup}>
                  <div className="phone-input-container">
                    <Controller
                      control={control}
                      name="home3"
                      render={({ field }) => (
                        <PhoneInput
                          specialLabel={t("HOME_INPUT_LABEL")}
                          placeholder={`${t("PHONE_NUMBER_PLACEHOLDER")}`}
                          disableDropdown={true}
                          countryCodeEditable={false}
                          onlyCountries={["do"]}
                          inputProps={{ maxLength: 15 }}
                          country={"do"}
                          value={
                            (homeDialCode3 && homeDialCode3.toString()) +
                            (homeNo3 && homeNo3.toString())
                          }
                          onChange={(value, country) => {
                            let dialCode = country.dialCode;
                            let phone = value.slice(
                              dialCode.length,
                              value.length
                            );
                            setHomeCountryCode3(country.countryCode);
                            setHomeDialCode3(dialCode);
                            setHomeNo3(phone);
                            field.onChange(value);
                            clearErrors("");
                          }}
                          inputExtraProps={{
                            style: {
                              position: "relative", // Ensure relative positioning for the container
                            },
                          }}
                          dropdownClass={styles.customDropdown} // Apply custom dropdown styles
                        />
                      )}
                      rules={{ validate: validateHomeNumber3 }}
                    />
                    {errors.home3 && (
                      <span className="errorMsg">{errors.home3.message}</span>
                    )}
                  </div>
                </Form.Group>
              </>
            )}
            <div className={styles.optionalButton}>
              <button
                type="button"
                onClick={() => setShowThirdContact(!showThirdContact)}
              >
                {showThirdContact
                  ? t("OPTIONAL_CONTACT_HIDE")
                  : t("OPTIONAL_CONTACT_SHOW")}
              </button>
            </div>
            <div className={styles.nextBtn}>
              <button
                type="submit"
                className={`${styles.nextButton} ${
                  loading ? styles.disabledButton : ""
                }`}
                disabled={loading}
              >
                {t("CREATE_ACCOUNT_BUTTON")}
              </button>
            </div>
          </Form>
          <RelationshipModal
            relationshipModalOpen={relationshipModalOpen}
            setRelationshipModalOpen={setRelationshipModalOpen}
            setValue={setValue}
            clearErrors={clearErrors}
            selectedRelationShip1={selectedRelationShip1}
            setSelectedRelationship1={setSelectedRelationship1}
          />
          <RelationshipModal2
            relationshipModalOpen2={relationshipModalOpen2}
            setRelationshipModalOpen2={setRelationshipModalOpen2}
            setValue={setValue}
            clearErrors={clearErrors}
            selectedRelationShip2={selectedRelationShip2}
            setSelectedRelationship2={setSelectedRelationship2}
          />

          <RelationshipModal3
            relationshipModalOpen3={relationshipModalOpen3}
            setRelationshipModalOpen3={setRelationshipModalOpen3}
            setValue={setValue}
            clearErrors={clearErrors}
            selectedRelationShip3={selectedRelationShip3}
            setSelectedRelationship3={setSelectedRelationship3}
          />
        </div>
      </div>
    </div>
  );
}

export default SignUpStepFourth;
