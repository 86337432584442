export const STATUS_CODES = {
  HEADER_IS_MISSING: 100,
  DEVICE_TYPE_NOT_ALLOWED: 101,
  INVALID_API_KEY: 102,
  TOKEN_IS_REQUIRED: 103,
  INVALID_TOKEN: 104,
  VALIDATION_ERROR: 105,
  STAFF_ERROR_MSG: 106,
  USER_NOT_FOUND: 107,
  BLOCKED_ACCOUNT: 108,
  SOCIAL_ALREADY_EXIT: 109,
  SOCIAL_ACCOUNT_NOT_FOUND: 110,
  SUCCESS: 200,
  INTERNAL_SERVER_ERROR: 500,
  INVALID_LOGIN_CREDENTIALS: 111,
  ACCOUNT_NOT_FOUND: 112,
  ACCOUNT_CURRENTLY_INACTIVE: 113,
  ACCOUNT_DELETED: 114,
  OLD_PASSWORD_DOES_NOT_MATCH: 115,
  EMAIL_ALREADY_IN_USE: 117,
  PHONE_NUMBER_ALREADY_USE: 118,
  VEHICLE_IMAGE_REQUIRED: 119,
  EMAIL_LINKED_SOCIAL_USER: 120,
  ALL_SECURITY_QUESTION_REQUIRED: 121,
  ALL_EMERGENCY_CONTACT_REQUIRED: 122,
  FAILED_TO_SEND_EMAIL: 123,
  INVALID_BLOOD_TYPE_ID: 124,
  INVALID_HEALTH_ISSUE_ID: 125,
  INVALID_SECURITY_QUESTION_ID: 126,
  INVALID_COLOR_ID: 127,
  VEHICLE_IMAGE_MAX_SIZE: 128,
  INVALID_VEHICLE_IMAGE_EXTENSION: 129,
  INVALID_SECURITY_QUESTION_ANSWER: 130,
  INVALID_SCANNER_JSON_FORMATE: 131,
  INVALID_EMERGENCY_CONTACT: 132,
  CODE_ALREADY_USE: 133,
  CODE_DOES_NOT_MATCH: 134,
  INVALID_CONTACT_RELATION_ID: 135,
  OTHER_HEALTH_ISSUE_REQUIRED: 136,
  EMAIL_LINKED_WITH_NORMAL_USER: 137,
  FAILED_UPLOAD_IMAGE: 138,
  INVALID_VEHICLE_ID: 139,
  INCORRECT_PIN_NUMBER: 140,
  VEHICLE_UNBLOCKED_BY_COMPANY: 141,
  VEHICLE_LOCKED_SUCCUSFULLY: 142,
  VEHICLE_UNLOCKED_SUCCSFULLY: 143,
  INVALID_DRIVER: 144,
  SAME_QUESTION_ANSWER_SELECTED: 145,
  NOTIFICATION_DISABLED: 146,
  NOTIFICATION_ENABLED: 147,
  LICENCE_PLATE_NUMBER_EXIST: 149,
  NATIONAL_ID_NUMBER_EXIST: 155,
  VEHICLE_SERIES_EXIST: 167,
  SERIAL_NO_NOT_EXSIT: 174,
  SOS_ALREADY_EXSIT: 178,
  SOS_IMG_REQUIRED: 183,
  INVALID_SOS_ID: 184,
  IMAGE_LIMIT: 185,
  INVALID_EXTENSION: 186,
  MAX_IMAGE_LIMIT: 187,
  INVALID_IMG_ID: 188,
  LOCATION_IS_REQUIRED: 189,
  DEVICE_LAST_LOCATION_NOT_FOUND: 190,
};
