import "../../Assests/Styles/Common.css";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "../DeleteAccount/DeleteAccount.module.css";
import { TbAlertTriangleFilled } from "react-icons/tb";
import PinInput from "react-pin-input";
import { useEffect, useState } from "react";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/StatusCode";
import { STATUS_MSG } from "../../utils/StatusMsg";
import Toster from "../../utils/Toster";
import { useDispatch, useSelector } from "react-redux";
import "../../Assests/Styles/Common.css";
import {
  appForGotPin,
  updateLockUnlockStatus,
  userLogoutClear,
} from "../../store/slices/userSlice";
import UpdatePinModal from "./UpdatePinModal";

function LockUnlockModal({ openLock, setOpenLock, selectedVehicleId }) {
  const { userToken, vehicleListData } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pinValue, setPinValue] = useState("");
  const [pinError, setPinError] = useState("");
  const [updatePinModalOpen, setUpdatePinModalOpen] = useState();
  const [locationData, setLocationData] = useState({
    latitude: "",
    longitude: "",
    location: "",
  });

  const [loading, setLoading] = useState(false);

  //========== function for show empty input when page refresh===========
  useEffect(() => {
    if (!openLock) {
      // Reset pinValue when the modal closes
      setPinValue("");
      setPinError("");
    }
  }, [openLock]);

  //====== function for manage late long in lock unlock api========
  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const latitude = position.coords.latitude;
  //         const longitude = position.coords.longitude;
  //         setLocationData({
  //           latitude,
  //           longitude,
  //           location: ``,
  //         });
  //       },
  //       (error) => {
  //         console.error("Error getting geolocation:", error);
  //       }
  //     );
  //   } else {
  //     console.error("Geolocation is not supported by this browser.");
  //   }
  // }, []);

  // =======function for forgot password api calling ========
  const changeStatus = async () => {
    if (!pinValue || pinValue.length !== 6) {
      setPinError(t("PIN_REQUIRED_MSG"));
      return;
    }
    setLoading(true);

    // const { latitude, longitude, location } = locationData;
    let requestData = new FormData();
    requestData.append("l_pin", pinValue);
    requestData.append("l_vehicle_id", selectedVehicleId);
    // requestData.append("l_location", location);
    // requestData.append("l_latitude", latitude ? latitude : "");
    // requestData.append("l_longitude", longitude ? longitude : "");

    try {
      const responsejson = await SublyApi.vehicleStatusChange(
        userToken,
        requestData
      );
      const ResponseCode = STATUS_MSG[responsejson.data.code];

      let modifiedEmployees;
      if (responsejson.code === STATUS_CODES.VEHICLE_LOCKED_SUCCUSFULLY) {
        modifiedEmployees = vehicleListData.map((obj) =>
          obj.id === selectedVehicleId ? { ...obj, is_lock: 1 } : obj
        );
        Toster(t("CHANGE_STATUS_LOCK"), "success");
        setLoading(false);
      } else if (
        responsejson.code === STATUS_CODES.VEHICLE_UNLOCKED_SUCCSFULLY
      ) {
        modifiedEmployees = vehicleListData.map((obj) =>
          obj.id === selectedVehicleId ? { ...obj, is_lock: 0 } : obj
        );
        Toster(t("CHANGE_STATUS_UNLOCK"), "success");
      } else if (responsejson.data.code === STATUS_CODES.VALIDATION_ERROR) {
        setPinValue("");
        setPinError(t("INCORRECT_PIN_ERROR"));
      } else if (responsejson.data.code === STATUS_CODES.INCORRECT_PIN_NUMBER) {
        Toster(t("INCORRECT_PIN_NUMBER"), "error");
      } else {
        Toster(t(ResponseCode), "error");
      }

      if (modifiedEmployees) {
        dispatch(updateLockUnlockStatus(modifiedEmployees));
      }
    } catch (error) {
      Toster(t("ERROR_OCCURRED"), "error");
    } finally {
      setLoading(false);
      setOpenLock(false);
    }
  };

  //--------- function for calling Forgot pin api--------
  const getForgotPin = async () => {
    dispatch(appForGotPin(userToken)).then((response) => {
      const responsejson = response.payload;
      const ResponseCode = STATUS_MSG[responsejson && responsejson.data.code];
      if (
        responsejson &&
        responsejson.data &&
        responsejson.code == STATUS_CODES.SUCCESS
      ) {
        setUpdatePinModalOpen(true);
      } else if (
        responsejson &&
        responsejson.data &&
        responsejson.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  return (
    <div className={styles.topModal}>
      <Modal
        show={openLock}
        onHide={() => {
          setOpenLock(false);
        }}
        className="modalCloseBtn"
        backdrop="static"
      >
        <Modal.Body className={styles.textAreaClass}>
          <div className={styles.iconText}>
            <TbAlertTriangleFilled
              icon="solar:danger-triangle-broken"
              color="red"
              width="100"
              height="100"
            />
          </div>

          <div className={styles.alertBox}>
            <h6>{t("ALERT")}</h6>
          </div>
          <div className={styles.enterPin}>{t("ENTER_LOCK_PIN")}</div>

          <div className={styles.lockPin}>
            <div className="lockPinINput">
              <PinInput
                length={6}
                type="numeric"
                style={{ padding: "10px, 10px", width: "100%" }}
                inputStyle={{ borderColor: "#DEDEDE" }}
                inputFocusStyle={{ borderColor: "#DEDEDE" }}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                // value={pinValue}
                initialValue={pinValue}
                onChange={(value) => {
                  setPinValue(value);
                  setPinError("");
                }}
              />
            </div>
          </div>
          {pinError && <div className="changeModalErrorMsg">{pinError}</div>}

          <div className={styles.forgotPin}>
            <p onClick={() => getForgotPin()}>{t("FORGOT_PIN_LINK")}</p>
          </div>

          <div className={styles.lockSubmitBtn}>
            <button
              onClick={() => changeStatus()}
              className={`${styles.nextButton} ${
                loading ? styles.disabledButton : ""
              }`}
              disabled={loading}
            >
              {t("SUBMIT_BUTTON")}
            </button>
          </div>
          <div className={styles.lockCancelBtn}>
            <button onClick={() => setOpenLock(false)}>{t("CANCEL")}</button>
          </div>
        </Modal.Body>
      </Modal>
      <UpdatePinModal
        updatePinModalOpen={updatePinModalOpen}
        setUpdatePinModalOpen={setUpdatePinModalOpen}
        onClose={() => setOpenLock(false)}
      />
    </div>
  );
}

export default LockUnlockModal;
