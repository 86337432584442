import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Home from "../AppComponents/Home/Home";
import Login from "../AuthComponents/Login/Login";
import ForgotPassword from "../AuthComponents/ForgotPassword/ForgotPassword";
import ScrollToTop from "../helpers/ScrollToTop";
import SignupFlow from "../AuthComponents/SignUp/SignupFlow";
import { useDispatch, useSelector } from "react-redux";
import ResetPassword from "../AuthComponents/ForgotPassword/ResetPassword";
import HomeList from "../AppComponents/Home/HomeList";
import Setting from "../AppComponents/Setting/Setting";
import AddVehicle from "../AppComponents/VehicleList/AddVehicle";
import SosHistory from "../AppComponents/Sos/SosHistory";
import Map from "../AppComponents/Map/Map";
import PersonalInformation from "../AppComponents/Profile/PersonalInformation";
import VehicleHistory from "../AppComponents/VehicleHistory/VehicleHistory";
import AboutUs from "../AppComponents/Content/AboutUs";
import ChangePassword from "../AppComponents/Setting/ChangePassword";
import PageNotFound from "../AppComponents/PageNotFound/PageNotFound";
import PrivacyPolicy from "../AppComponents/Content/PrivacyPolicy";
import TermsCondition from "../AppComponents/Content/TermsCondition";
import EditPersonalInfo from "../AppComponents/EditProfile/EditPersonalInfo";
import EditSecurityQuestion from "../AppComponents/EditProfile/EditSecurityQuestion";
import EditEmergencyContact from "../AppComponents/EditProfile/EditEmergencyContact";
import Notification from "../AppComponents/Setting/Notification";
import VehicleDetail from "../AppComponents/VehicleHistory/VehicleDetail";
import SublyApi from "../helpers/Api";
import FireBaseNotification from "../FireBaseNotification";
import { fetchNotificationList, setActiveTab } from "../store/slices/userSlice";
import HistoryDetail from "../AppComponents/VehicleHistory/HistoryDetail";
import moment from "moment";
import "moment/locale/es";
import i18next from "i18next";
import { LanguageContext } from "../Context/LanguageContext";
//-------Create a component for manage routing--------
function Routers() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.userToken);
  const currentUserDetail = useSelector((state) => state.user.currentUser);

  const current_language = useSelector((state) => state.user.user_language);

  const { selectedLanguage, changeLanguage } = useContext(LanguageContext);

  const PublicRoute = ({ children }) => {
    return !isAuthenticated ? children : <Navigate to="/home-list" />;
  };

  const PrivateRoute = ({ children, path }) => {
    if (currentUserDetail.security_question_exist === "false") {
      if (isAuthenticated) {
        dispatch(setActiveTab("Security Question"));
        if (path != "/edit-security-question") {
          return <Navigate to="/edit-security-question" />;
        }
      }
    } else if (currentUserDetail.emergency_contact_exist === "false") {
      if (isAuthenticated) {
        dispatch(setActiveTab("Emergency contact"));
        if (path != "/edit-emergency-contact") {
          return <Navigate to="/edit-emergency-contact" />;
        }
      }
    }

    return isAuthenticated ? children : <Navigate to="/login" />;
  };

  const handleSubmit = (latitude, longitude) => {
    if (latitude && longitude) {
      let Postdata = new FormData();
      Postdata.append("location", "unknown");
      Postdata.append("latitude", latitude);
      Postdata.append("longitude", longitude);
      SublyApi.postDriverLocation(isAuthenticated, Postdata);
    }
  };

  useEffect(() => {
    if (isAuthenticated !== null) {
      dispatch(
        fetchNotificationList({
          userToken: isAuthenticated,
          page_no: 1,
          onLoadMore: true,
        })
      );
      const getLocationAndSubmit = () => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            handleSubmit(latitude, longitude);
          },
          (error) => {
            console.error("Error getting geolocation:", error);
          }
        );
      };

      getLocationAndSubmit();

      const intervalId = setInterval(() => {
        getLocationAndSubmit();
      }, 300000);

      return () => clearInterval(intervalId);
    }
  }, [isAuthenticated]);

  const handleSelect = () => {};
  useEffect(() => {
    changeLanguage(current_language);
    i18next.changeLanguage(current_language);
    moment.locale(current_language);
  }, [current_language]);

  return (
    <>
      <Router basename={"/"}>
        <ScrollToTop />
        <FireBaseNotification />
        <Routes>
          <Route
            exact
            path="/"
            element={<PublicRoute>{<Home />}</PublicRoute>}
          />
          <Route
            exact
            path="/login"
            element={<PublicRoute>{<Login />}</PublicRoute>}
          />
          <Route
            exact
            path="/personal-information"
            element={<PrivateRoute>{<PersonalInformation />}</PrivateRoute>}
          />

          <Route
            exact
            path="/edit-personal-info"
            element={<PrivateRoute>{<EditPersonalInfo />}</PrivateRoute>}
          />

          <Route
            exact
            path="/edit-security-question"
            element={
              <PrivateRoute path="/edit-security-question">
                {<EditSecurityQuestion />}
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-emergency-contact"
            element={
              <PrivateRoute path="/edit-emergency-contact">
                {<EditEmergencyContact />}
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/forgot-password"
            element={<PublicRoute>{<ForgotPassword />}</PublicRoute>}
          />
          <Route
            exact
            path="/reset-password"
            element={<PublicRoute>{<ResetPassword />}</PublicRoute>}
          />
          <Route
            exact
            path="/sign-up"
            element={<PublicRoute>{<SignupFlow />}</PublicRoute>}
          />
          <Route
            exact
            path="/home-list"
            element={<PrivateRoute>{<HomeList />}</PrivateRoute>}
          />

          <Route
            exact
            path="/add-vehicle-list"
            element={<PrivateRoute>{<AddVehicle />}</PrivateRoute>}
          />

          <Route
            exact
            path="/vehicle-detail/:id"
            element={<PrivateRoute>{<VehicleDetail />}</PrivateRoute>}
          />

          <Route
            exact
            path="/setting"
            element={<PrivateRoute>{<Setting />}</PrivateRoute>}
          />

          <Route
            exact
            path="/notification"
            element={<PrivateRoute>{<Notification />}</PrivateRoute>}
          />
          <Route
            exact
            path="/about-us"
            element={<PrivateRoute>{<AboutUs />}</PrivateRoute>}
          />
          <Route
            exact
            path="/privacy-policy"
            element={<PrivateRoute>{<PrivacyPolicy />}</PrivateRoute>}
          />
          <Route
            exact
            path="/terms-condition"
            element={<PrivateRoute>{<TermsCondition />}</PrivateRoute>}
          />

          <Route
            exact
            path="/change-password"
            element={<PrivateRoute>{<ChangePassword />}</PrivateRoute>}
          />

          <Route
            exact
            path="/vehicle-history"
            element={<PrivateRoute>{<VehicleHistory />}</PrivateRoute>}
          />

          <Route
            exact
            path="/history-detail/:id"
            element={<PrivateRoute>{<HistoryDetail />}</PrivateRoute>}
          />

          <Route
            exact
            path="/sos-history"
            element={<PrivateRoute>{<SosHistory />}</PrivateRoute>}
          />
          <Route
            exact
            path="/map"
            element={<PrivateRoute>{<Map />}</PrivateRoute>}
          />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </>
  );
}
export default Routers;
