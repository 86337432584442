export const STATUS_MSG = {
  100: "HEADER_IS_MISSING",
  101: "DEVICE_TYPE_NOT_ALLOWED",
  102: "INVALID_API_KEY",
  103: "TOKEN_REQUIRE_AUTHENTICATION",
  104: "INVALID_TOKEN",
  105: "VALIDATION_ERROR_CODE",
  106: "STAFF_ERROR_MSG",
  107: "USER_NOT_FOUND",
  108: "BLOCKED_ACCOUNT",
  109: "SOCIAL_ALREADY_EXIT",
  110: "SOCIAL_ACCOUNT_NOT_FOUND",
  200: "SUCCESS",
  500: "INTERNAL_SERVER_ERROR",
  111: "INVALID_LOGIN_CREDENTIAL",
  112: "ACCOUNT_NOT_FOUND",
  113: "ACCOUNT_CURRENTLY_INACTIVE",
  114: "ACCOUNT_DELETED",
  115: "OLD_PASSWORD_DOES_NOT_MATCH",
  117: "EMAIL_ALREADY_IN_USE",
  118: "PHONE_NUMBER_ALREADY_USE",
  119: "VEHICLE_IMAGE_REQUIRED",
  120: "EMAIL_LINKED_SOCIAL_USER",
  121: "ALL_SECURITY_QUESTION_REQUIRED",
  122: "ALL_EMERGENCY_CONTACT_REQUIRED",
  123: "FAILED_TO_SEND_EMAIL",
  124: "INVALID_BLOOD_TYPE_ID",
  125: "INVALID_HEALTH_ISSUE_ID",
  126: "INVALID_SECURITY_QUESTION_ID",
  127: "INVALID_COLOR_ID",
  128: "VEHICLE_IMAGE_MAX_SIZE",
  129: "INVALID_VEHICLE_IMAGE_EXTENSION",
  130: "INVALID_SECURITY_QUESTION_ANSWER",
  131: "INVALID_SCANNER_JSON_FORMATE",
  132: "INVALID_EMERGENCY_CONTACT",
  133: "CODE_ALREADY_USE",
  134: "CODE_DOES_NOT_MATCH",
  135: "INVALID_CONTACT_RELATION_ID",
  136: "OTHER_HEALTH_ISSUE_REQUIRED",
  137: "EMAIL_LINKED_WITH_NORMAL_USER",
  138: "FAILED_UPLOAD_IMAGE",
  139: "INVALID_VEHICLE_ID",
  140: "INCORRECT_PIN_NUMBER",
  141: "VEHICLE_UNBLOCKED_BY_COMPANY",
  142: "VEHICLE_LOCKED_SUCCUSFULLY",
  143: "VEHICLE_UNLOCKED_SUCCSFULLY",
  144: "INVALID_DRIVER",
  145: "SAME_QUESTION_ANSWER_SELECTED",
  146: "NOTIFICATION_DISABLED",
  147: "NOTIFICATION_ENABLED",
  149: "LICENCE_PLATE_NUMBER_EXIST",
  155: "NATIONAL_ID_NUMBER_EXIST",
  167: "VEHICLE_SERIES_EXIST",
  174: "SERIAL_NO_NOT_EXSIT",
  178: "SOS_ALREADY_EXSIT",
  183: "SOS_IMG_REQUIRED",
  184: "INVALID_SOS_ID",
  185: "IMAGE_LIMIT",
  186: "INVALID_EXTENSION",
  187: "MAX_IMAGE_LIMIT",
  188: "INVALID_IMG_ID",
  189: "LOCATION_IS_REQUIRED",
  190: "DEVICE_LAST_LOCATION_NOT_FOUND",
};
