import "../../Assests/Styles/Common.css";
import { useTranslation } from "react-i18next";
import styles from "../../AuthComponents/SignUp/SignUp.module.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import "react-datepicker/dist/react-datepicker.css";
import { useForm, Controller } from "react-hook-form";
import { Container, Form } from "react-bootstrap";
import { IoMdArrowDropdown } from "react-icons/io";
import "react-phone-input-2/lib/style.css";
import { sanitizeHtmlTags } from "../../utils/SanitizeHtmlTag";
import { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BikeColorModal from "../../AuthComponents/SignUp/BikeColorModal";
import { IoCloudUploadOutline } from "react-icons/io5";
import scannerImg from "../../Assests/Images/scanner.png";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import {
  addNewVehicle,
  fetchMasterData,
  userLogoutClear,
} from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { IoCloseOutline } from "react-icons/io5";
import { STATUS_MSG } from "../../utils/StatusMsg";
import { STATUS_CODES } from "../../utils/StatusCode";
import Toster from "../../utils/Toster";
import { useNavigate } from "react-router-dom";
import mime from "mime";
import Loader from "../../utils/Loader/Loader";
import dayjs from "dayjs";
import CameraModal from "../../AuthComponents/SignUp/CameraModel";

function AddVehicle() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userToken, masterList, isLoading } = useSelector(
    (state) => state.user
  );
  const [bikeColorModalOpen, setBikeColorModalOpen] = useState(false);
  const [photoUploaded, setPhotoUploaded] = useState(false);
  const [selectedBikeColor, setSelectedBikeColor] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [imgError, setImgError] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [showCameraModal, setShowCameraModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOpenCameraModal = () => {
    setShowCameraModal(true);
  };

  const handleCloseCameraModal = () => {
    setShowCameraModal(false);
  };

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       setLatitude(position.coords.latitude);
  //       setLongitude(position.coords.longitude);
  //     });
  //   }
  // }, []);

  //========== function for manage date and format it as yyyy-mm-dd========
  const handleDateChange = (date) => {
    const formattedYear = date ? new Date(date).getFullYear() : null;
    setSelectedDate(formattedYear);
  };

  //======== function for calling masterData api========
  useEffect(() => {
    // Check if masterList does not have colors
    if (!masterList || !masterList.colors) {
      const refKey = "colours";
      const getMasterDataList = async () => {
        dispatch(fetchMasterData({ refKey: refKey }));
      };
      getMasterDataList();
    }
  }, []);

  //======== function for upload image onChnage========

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/webp",
    ];

    if (file && allowedTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedFile(reader.result); // Set binary content of the image to state
      };
      reader.readAsDataURL(file);
      setImgError(""); // Clear any previous error message
      setPhotoUploaded(true);
    } else {
      setImgError(
        "Invalid file type. Please select a .jpg, .jpeg, .gif, .png, or .webp file."
      ); // Set error message
      setPhotoUploaded(false);
    }
  };

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setSelectedFile(reader.result); // Set binary content of the image to state
  //     };
  //     reader.readAsDataURL(file);
  //   }
  //   setImgError("");
  //   setPhotoUploaded(true);
  // };

  //======= useForm property for manage validation=========
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useForm();

  function b64toBlob(cropData, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;
    var byteCharacters = window.atob(cropData); //decode string
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  function getRandom() {
    return Math.floor(Math.random() * 9000000000) + 1000000000;
  }

  //====== Function to disable future dates and limit selection to current and previous year======
  const disableFutureDates = (date) => {
    const currentYear = new Date().getFullYear();
    const nextOneYears = currentYear + 1;
    return date.isAfter(new Date(nextOneYears, 11, 31));
  };

  // ===========Calling APi for addding new  vehicle===========
  const onSubmit = async (formData) => {
    if (!selectedFile) {
      setImgError(t("SELECT_IMAGE"));
      return;
    }
    setIsSubmitting(true);
    let formDataVehicle = "";
    var blockCrop = selectedFile && selectedFile.split(";");
    if (blockCrop.length < 2) {
      console.error("Invalid selected file format");
      setIsSubmitting(false);
      return; // Or handle the error accordingly
    }
    // Get the content type of the image
    var cropContentTypes = blockCrop[0].split(":")[1]; // In this case "image/png"
    // get the real base64 content of the file
    var blockRealData = blockCrop[1].split(",")[1];
    var cropBlobImg = await b64toBlob(blockRealData, cropContentTypes);
    formDataVehicle = new FormData();
    formDataVehicle.append(
      "file",
      cropBlobImg,
      getRandom() + "." + mime.getExtension(cropBlobImg.type)
    );

    const requestData = new FormData();
    requestData.append("d_license_plate_no", formData.licenseNumber);
    requestData.append("d_veh_series_no", formData.serialnumber);
    requestData.append("d_veh_model", formData.modalNumber);
    requestData.append("d_veh_colour_id", selectedBikeColor.id);
    requestData.append("d_veh_year", selectedDate);
    requestData.append("d_veh_image", formDataVehicle.get("file"));
    // requestData.append("d_latitude", latitude);
    // requestData.append("d_longitude", longitude);

    requestData.append(
      "d_scanner_json",
      JSON.stringify({
        device_id: "1",
        serial_no: "",
        version: 48,
        manufacturer: "USBANA2022",
        firmware_id: 2186,
      })
    );
    await dispatch(
      addNewVehicle({
        userToken: userToken,
        requestData: requestData,
      })
    ).then((responsejson) => {
      const response = responsejson.payload;
      const ResponseCode = STATUS_MSG[response.data.code];
      if (response.code === STATUS_CODES.SUCCESS) {
        Toster(t("ADD_VEHICLE_SUCCESSFULLY"), "success");
        navigate("/home-list");
        setIsSubmitting(false);
      } else if (response.data.code === STATUS_CODES.USER_NOT_FOUND) {
        Toster(t("USER_NOT_FOUND"), "error");
      } else if (
        response.data.code === STATUS_CODES.LICENCE_PLATE_NUMBER_EXIST
      ) {
        Toster(t("LICENCE_PLATE_NUMBER_EXIST"), "error");
        setIsSubmitting(false);
      } else if (response.data.code === STATUS_CODES.VALIDATION_ERROR) {
        Toster(response.data.message, "error");
        setIsSubmitting(false);
      } else {
        Toster(t(ResponseCode), "error");
        setIsSubmitting(false);
      }
      if (
        response &&
        response.data &&
        response.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        // Toster("Your session expired", "error");
        dispatch(userLogoutClear());
      }
    });
  };

  //====== disable space on key press input password field=======
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  return (
    <div className={styles.signpWrapper}>
      {isLoading ? <Loader /> : ""}
      <div className={styles.tittle}>
        <div className={styles.backIcon}>
          <IoMdArrowRoundBack
            onClick={() => {
              navigate("/home-list");
            }}
          />
        </div>
        <div className={styles.signUpTitle}>
          <p>{t("ADD_NEW_BIKE_TITLE")}</p>
        </div>
      </div>
      <Container>
        <div className={styles.inputForm}>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.formSection}
          >
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("LICENSE_NUMBER_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("LICENSE_PLACEHOLDER_TEXT")}`}
                type="text"
                {...register("licenseNumber", {
                  onChange: (e) => {
                    handleKeyPress(e);
                  },
                  required: {
                    value: true,
                    message: `${t("LICENSE_REQUIRED_MESSAGE")}`,
                  },
                  minLength: {
                    value: 2,
                    message: `${t("VALIDATION_MINLENGTH")}`,
                  },
                  maxLength: {
                    value: 15,
                    message: `${t("VALIDATION_MAXLENGTH")}`,
                  },
                  ...sanitizeHtmlTags(),
                })}
              />

              {errors.licenseNumber && (
                <span className="errorMsg">{errors.licenseNumber.message}</span>
              )}
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("MODAL_NUMBER_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("MODAL_PLACEHOLDER_TEXT")}`}
                type="text"
                {...register("modalNumber", {
                  onChange: (e) => {
                    handleKeyPress(e);
                  },
                  required: {
                    value: true,
                    message: `${t("MODAL_REQUIRED_MESSAGE")}`,
                  },
                  minLength: {
                    value: 2,
                    message: `${t("VALIDATION_MINLENGTH")}`,
                  },
                  maxLength: {
                    value: 15,
                    message: `${t("VALIDATION_MAXLENGTH")}`,
                  },
                  ...sanitizeHtmlTags(),
                })}
              />

              {errors.modalNumber && (
                <span className="errorMsg">{errors.modalNumber.message}</span>
              )}
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("BIKE_COLOR_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("BIKE_COLOR__PLACEHOLDER_TEXT")}`}
                type="text"
                readOnly
                onClick={() => setBikeColorModalOpen(true)}
                {...register("bikeColor", {
                  required: {
                    value: true,
                    message: `${t("BIKE_COLOR__REQUIRED_MESSAGE")}`,
                  },
                })}
              />
              <IoMdArrowDropdown
                className="dropDown"
                onClick={() => {
                  setBikeColorModalOpen(true);
                }}
              />

              {errors.bikeColor && (
                <span className="errorMsg">{errors.bikeColor.message}</span>
              )}
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <div className="dateOfBirth">
                <Controller
                  control={control}
                  name="yearOfBike"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["MobileDatePicker"]}>
                        <DemoItem>
                          <MobileDatePicker
                            views={["year"]}
                            label={t("BIKE_YEAR_LABEL")}
                            defaultValue=""
                            onChange={(newValue) => {
                              onChange(newValue);
                              handleDateChange(newValue);
                              clearErrors("yearOfBike"); // Handle date change to remove error message
                            }}
                            shouldDisableDate={disableFutureDates}
                            minDate={dayjs(new Date("1901-01-01"))}
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  )}
                />

                <div className="errorMsg">
                  {errors.yearOfBike && <p>{t("SELECT_BIKE_YEAR")}</p>}
                </div>
              </div>
            </Form.Group>
            <Form.Group className={styles.FormGroup}>
              <Form.Label className={styles.FormLabel}>
                {t("SERIAL_NUMBER_LABEL")}
              </Form.Label>
              <Form.Control
                className={styles.FormInput}
                placeholder={`${t("SERIAL_NUMBER_PLACEHOLDER_TEXT")}`}
                type="text"
                {...register("serialnumber", {
                  onChange: (e) => {
                    handleKeyPress(e);
                  },
                  required: {
                    value: true,
                    message: `${t("SERIAL_NUMBER_REQUIRED_MESSAGE")}`,
                  },
                  minLength: {
                    value: 5,
                    message: `${t("SERIAL_VALIDATION_MINLENGTH")}`,
                  },
                  maxLength: {
                    value: 50,
                    message: `${t("SERIAL_VALIDATION_MAXLENGTH")}`,
                  },
                  ...sanitizeHtmlTags(),
                })}
              />

              {errors.serialnumber && (
                <span className="errorMsg">{errors.serialnumber.message}</span>
              )}
            </Form.Group>

            <div className={styles.uploadBox}>
              <p>{t("UPLOAD_BIKE_PHOTO_LABEL")}</p>
              {selectedFile ? (
                <div className={styles.imgUpload}>
                  <IoCloseOutline
                    className={styles.closeIcon}
                    onClick={() => {
                      setSelectedFile(null);
                      setPhotoUploaded(false);
                    }}
                  />
                  <img
                    className={styles.afterUploadImg}
                    src={selectedFile}
                    alt="Uploaded"
                  />
                </div>
              ) : (
                <div className={styles.uploadIcon}>
                  <label htmlFor="file-input" className={styles.uploadLabel}>
                    <IoCloudUploadOutline className="upload-icon" />
                    {t("UPLOAD_PHOTO")}
                  </label>
                </div>
              )}

              <input
                type="file"
                id="file-input"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <div className="errorMsg">
                {imgError && <div className="errorMsg">{imgError}</div>}
              </div>
            </div>

            <div className={styles.qrScanner}>
              <p>{t("SCANER_QR_CODE")}</p>
              <div className={styles.scannerImg}>
                <img src={scannerImg} onClick={handleOpenCameraModal}></img>
              </div>
            </div>

            <div className={styles.nextBtn}>
              <button
                type="submit"
                className={`${styles.nextButton} ${
                  isSubmitting ? styles.disabledButton : ""
                }`}
                disabled={isSubmitting}
              >
                {t("ADD_NEW_BIKE_TITLE")}
              </button>
            </div>
          </Form>
          <BikeColorModal
            bikeColorModalOpen={bikeColorModalOpen}
            setBikeColorModalOpen={setBikeColorModalOpen}
            setValue={setValue}
            clearErrors={clearErrors}
            selectedBikeColor={selectedBikeColor}
            setSelectedBikeColor={setSelectedBikeColor}
          />
          <CameraModal
            show={showCameraModal}
            handleClose={handleCloseCameraModal}
            setValue={setValue}
          />
        </div>
      </Container>
    </div>
  );
}

export default AddVehicle;
