import React, { useEffect, useRef, useState } from "react";
import "leaflet/dist/leaflet.css";
import { useTranslation } from "react-i18next";
import styles from "./VehicleHistory.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchVehicleDetails,
  sendVehicleNotification,
  userLogoutClear,
} from "../../store/slices/userSlice";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/StatusCode";
import { STATUS_MSG } from "../../utils/StatusMsg";
import Toster from "../../utils/Toster";
import Switch from "react-switch";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import { validationSchema } from "../../utils/ValidationSchema";
import { MapContainer, TileLayer, Marker, Circle } from "react-leaflet";
import DriverLocation from "../../Assests/Images/driverLocation1.png";
import RedBike from "../../Assests/Images/redBike.png";

import L from "leaflet";
import Loader from "../../utils/Loader/Loader";
import { GoogleAutoComplete } from "../../Common/CommonComp";
import { Autocomplete } from "@react-google-maps/api";

function VehicleDetail() {
  const { vehicleData, userToken } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const [geofenceEnabled, setGeofenceEnabled] = useState(false);
  const [geofenceKey, setGeofenceKey] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm();

  const [currentLocation, setCurrentLocation] = useState([18.4861, -69.9312]);

  const [vehicleLocation, setVehicleLocation] = useState([]);
  const [distance, setDistance] = useState(0);

  const [latitude1, setLatitude1] = useState("");
  const [longitude1, setLongitude1] = useState("");

  const [sourceGeometry, setSourceGeometry] = useState({
    address: "",
    lat: 0,
    lng: 0,
  });

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       setLatitude1(position.coords.latitude);
  //       setLongitude1(position.coords.longitude);
  //     });
  //   }
  // }, []);

  const toggleGeofence = (e) => {
    setGeofenceEnabled(!geofenceEnabled);
    setGeofenceKey(!geofenceKey);
    // if (!e) {
    //   setGeofenceEnabled(!geofenceEnabled);
    //   setGeofenceKey(!geofenceKey);
    // } else {
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(
    //       (position) => {
    //         const { latitude, longitude } = position.coords;

    //         setCurrentLocation([latitude, longitude]);
    //         setGeofenceEnabled(!geofenceEnabled);
    //         setGeofenceKey(!geofenceKey);
    //       },
    //       (error) => {
    //         Toster(t("TURN_ON_LOCATION"), "error");
    //         return;
    //       }
    //     );
    //   } else {
    //     // Geolocation is not supported by the browser
    //     alert("Geolocation is not supported by this browser.");
    //   }
    // }
  };

  const useFormValue = watch();

  const OnSubmit = (data) => {
    setIsLoading(true);

    let requestData = new FormData();
    requestData.append("vehicle_id", id);

    requestData.append(
      "distance",
      geofenceEnabled ? JSON.parse(data.KiloMeter) : ""
    );
    requestData.append("geo_fence", geofenceEnabled ? "on" : "off");
    // requestData.append("geo_fence_location", "");
    // requestData.append(
    //   "geo_fence_latitude",
    //   geofenceEnabled ? currentLocation[0] : ""
    // );
    // requestData.append(
    //   "geo_fence_longitude",
    //   geofenceEnabled ? currentLocation[1] : ""
    // );

    SublyApi.changeGeoFenceStatus(userToken, requestData).then(
      (responsejson) => {
        const ResponseCode = STATUS_MSG[responsejson.data.code];

        setIsLoading(false);
        if (responsejson.code === STATUS_CODES.SUCCESS) {
          Toster(t("GEO_FENCE_STATUS_CHANGE_SUCCESFULLY"), "success");
          navigate("/home-list");
        } else if (responsejson.data.code === STATUS_CODES.VALIDATION_ERROR) {
          Toster(responsejson.data.message, "error");
        } else if (
          responsejson.code === STATUS_CODES.INVALID_TOKEN ||
          responsejson.data.code === STATUS_CODES.INVALID_TOKEN
        ) {
          dispatch(userLogoutClear());
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    );
  };

  useEffect(() => {
    const getVehicleDetail = async () => {
      setIsLoading(true);
      dispatch(
        fetchVehicleDetails({ userToken: userToken, vehicleId: id })
      ).then((res) => {
        const response = res.payload;
        setIsLoading(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.INVALID_TOKEN
        ) {
          dispatch(userLogoutClear());
        } else if (
          response &&
          response.data &&
          response.code === STATUS_CODES.SUCCESS
        ) {
          setGeofenceEnabled(response.data.geo_fence_status === 1);
          const lat = response?.data?.geo_fence_latitude;
          const lon = response?.data?.geo_fence_longitude;

          const vehiclelat = response?.data?.latitude;
          const vehiclelon = response?.data?.longitude;

          if (lat !== null && lon !== null) {
            setCurrentLocation([lat, lon]);
          } else if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const currentLatitude = position.coords.latitude;
                const currentLongitude = position.coords.longitude;

                setCurrentLocation([currentLatitude, currentLongitude]);
              },
              (error) => {
                // Handle error if geolocation fails (e.g., location access denied)
                setCurrentLocation([18.4861, -69.9312]);
              }
            );
          }

          if (vehiclelat !== null && vehiclelon !== null) {
            setVehicleLocation([vehiclelat, vehiclelon]);
          }

          if (response.data.geo_fence_status) {
            setDistance(response.data.distance);
          }

          if (response.data.geo_fence_status === 1 && response.data.distance) {
            setValue("kmInputs", response.data.distance);
            setValue("KiloMeter", response.data.distance);
          }
        }
      });
    };
    getVehicleDetail();
  }, [id]);

  const getMarkerIcon = (img, center) => {
    return L.icon({
      iconUrl: img,
      iconSize: center ? [40, 40] : [20, 20],
    });
  };

  const handleNotify = async (formData) => {
    setIsLoading(true);
    const requestData = new FormData();
    requestData.append("vehicle_id", id);
    // requestData.append("latitude1", latitude1);
    // requestData.append("longitude1", longitude1);

    await dispatch(
      sendVehicleNotification({
        userToken: userToken,
        requestData: requestData,
      })
    ).then((responsejson) => {
      const response = responsejson.payload;
      const ResponseCode = STATUS_MSG[response.data.code];
      setIsLoading(false);
      if (response.code === STATUS_CODES.SUCCESS) {
        Toster(t("NOTIFY_SEND"), "success");
      } else if (response.data.code === STATUS_CODES.USER_NOT_FOUND) {
        Toster(t("USER_NOT_FOUND"), "error");
      } else if (
        response.data.code === STATUS_CODES.LICENCE_PLATE_NUMBER_EXIST
      ) {
        Toster(t("LICENCE_PLATE_NUMBER_EXIST"), "error");
      } else if (response.data.code === STATUS_CODES.VALIDATION_ERROR) {
        Toster(response.data.message, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
      if (
        response &&
        response.data &&
        response.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        // Toster("Your session expired", "error");
        dispatch(userLogoutClear());
      }
    });
  };

  // const handleKeyPress = (e, t) => {
  //   if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  // };

  // const autocompleteSource = useRef(null);

  // const handlePlaceSelected = (place) => {
  //   const selectedPlace = autocompleteSource?.current?.getPlace();

  //   setSourceGeometry({
  //     address: selectedPlace?.formatted_address,
  //     lat: Number(selectedPlace?.geometry?.location?.lat()),
  //     lng: Number(selectedPlace?.geometry?.location?.lng()),
  //   });

  //   setValue("DriverLocation", selectedPlace?.formatted_address);
  // };

  return (
    <div className={styles.settingWrapper}>
      {isLoading && <Loader />}
      <div className={styles.topSection}>
        <div className={styles.formStart}>
          <div className={styles.backIcon}>
            <IoMdArrowRoundBack onClick={() => navigate(-1)} />
          </div>
          <div className={styles.profileTitle}>
            <p>{t("VEHICLE_DETAIL_TITLE")}</p>
          </div>
        </div>
      </div>

      <div className={styles.bikeDetail}>
        <div className={styles.bikeHistory}>
          {/* <div className={styles.notify}>
            <button type="button" onClick={handleNotify}>
              {t("NOTIFY")}
            </button>
          </div> */}
          <div className={styles.bikeImage}>
            <img
              src={vehicleData.vehicle_image}
              alt={vehicleData.vehicle_model}
            />
          </div>

          <div className={styles.vehicleText}>
            <div className={styles.textOption}>
              <h5>{t("LICENCE_PLATE")}</h5>
              <p>{vehicleData.license_plate_number}</p>
            </div>
          </div>

          <div className={styles.textOption}>
            <h5>{t("MODAL_NUMBER_TEXT")}</h5>
            <p>{vehicleData.vehicle_model}</p>
          </div>

          <div className={styles.textOption}>
            <h5>{t("VEHICLE_YEAR")}</h5>
            <p>{vehicleData.year}</p>
          </div>

          <div className={styles.textOption}>
            <h5>{t("SERIAL_NUMBER_TEXT")}</h5>
            <p>{vehicleData.series_no ? vehicleData.series_no : "NA"}</p>
          </div>

          <div className={styles.textOption}>
            <h5>{t("VEHICLE_COLOR")}</h5>
            <p>{vehicleData.colour}</p>
          </div>

          <div className={styles.textOption}>
            <h5>{t("COMPANY_NAME")}</h5>
            <p>{vehicleData.assign_by || "Personal"}</p>
          </div>

          <div className={styles.toggleFlex}>
            <h3>{t("GEO_FENCE_BTN")}</h3>
            <div className={styles.addGeoFence}>
              <div className={styles.switchOn}>
                <Switch
                  checked={geofenceEnabled}
                  onChange={(e) => {
                    toggleGeofence(e);
                  }}
                  className="react-switch custom-switch"
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onColor="#FF2A00"
                  offColor="#d3d3d3"
                />
              </div>
            </div>
          </div>

          <div className={styles.kmINput}>
            {geofenceEnabled ? (
              <Form
                noValidate
                onSubmit={handleSubmit(OnSubmit)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && e.target.tagName === "INPUT") {
                    e.preventDefault(); // Prevent the default form submission
                  }
                }}
              >
                <Form.Group>
                  <Form.Label className={styles.label}>
                    {t("KILOMETER_LABEL")}
                  </Form.Label>
                  <Form.Control
                    {...register("KiloMeter", validationSchema.KiloMeter)}
                    isInvalid={!!errors.KiloMeter}
                    className="fenceInput"
                    type="number"
                    step="1"
                    placeholder={`${t("ENTER_KM_PLACEHOLDER")}`}
                    onInput={(e) => {
                      const value = e.target.value;
                      const parts = value.split(".");

                      if (parts.length > 1 && parts[1].length > 2) {
                        e.target.value = `${parts[0]}.${parts[1].slice(0, 2)}`;
                      }
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t(errors.KiloMeter?.message)}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* <Form.Group style={{ marginTop: "10px" }}>
                  <Form.Label className={styles.label}>
                    {t("LOCATION")}
                  </Form.Label>

                  <Autocomplete
                    onLoad={(auto) => {
                      autocompleteSource.current = auto;
                    }}
                    onPlaceChanged={(place) => {
                      handlePlaceSelected(place);
                    }}
                  >
                    <Form.Control
                      className="fenceInput"
                      type="text"
                      placeholder={t("ENTER_LOCATION")}
                      isInvalid={!!errors.DriverLocation}
                      {...register("DriverLocation", {
                        ...validationSchema.DriverLocation,
                        onChange: (e) => {
                          handleKeyPress(e);
                        },
                      })}
                    />
                  </Autocomplete>

                  <Form.Control.Feedback
                    type="invalid"
                    style={{ display: "block" }}
                  >
                    {t(errors.DriverLocation?.message)}
                  </Form.Control.Feedback>
                </Form.Group> */}

                <div className="mapItems">
                  <div style={{ height: "300px", width: "100%" }}>
                    {currentLocation.length > 0 && (
                      <MapContainer
                        key={`${currentLocation[0]}-${currentLocation[1]}`}
                        center={currentLocation}
                        attributionControl={false}
                        scrollWheelZoom={false}
                        zoom={16}
                        touchZoom={true}
                        doubleClickZoom={true}
                        style={{ height: "100%", width: "100%" }}
                        className="mapContainer"
                      >
                        <TileLayer
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />

                        <Circle
                          center={currentLocation}
                          radius={distance * 1000}
                          fillColor="#FF2A00"
                          color="#FF2A00"
                          width="1"
                        />
                        <Marker
                          position={currentLocation}
                          icon={getMarkerIcon(DriverLocation, 0)}
                        />

                        {vehicleLocation?.length > 0 ? (
                          <Marker
                            position={vehicleLocation}
                            icon={getMarkerIcon(RedBike, 1)}
                          />
                        ) : (
                          ""
                        )}
                      </MapContainer>
                    )}
                  </div>
                </div>
                <div className={styles.update}>
                  <button type="submit">{t("UPDATE")}</button>
                </div>
              </Form>
            ) : (
              <div className={styles.update}>
                <button
                  onClick={() => {
                    OnSubmit();
                  }}
                >
                  {t("UPDATE")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VehicleDetail;
