import { t } from "i18next";

export const sanitizeHtmlTags = () => ({
  validate: (value) =>
    !/<(\/)?[a-z][\s\S]*>/i.test(value) || "HTML tags are not allowed.",
});

export const validationSchema = {
  KiloMeter: {
    required: `${t("KM_REQUIRED_MSG")}`,
    validate: {
      notNegative: (value) => {
        if (value <= 0.4 || value > 50) {
          return t("KM_MIN_MAX_ERROR");
        }
        return true;
      },
    },
  },
  PICKUP_ADDRESS: {
    required: "LOCATION_REQ",
  },
};
