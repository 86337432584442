import "leaflet/dist/leaflet.css";
import { useTranslation } from "react-i18next";
import styles from "../Map/Map.module.css";
import { FaBell } from "react-icons/fa";
import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, Circle } from "react-leaflet";
import HomeFooterOption from "../Home/HomeFooterOption";
import "../../Assests/Styles/Common.css";
import { getMapData, userLogoutClear } from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../utils/StatusCode";
import { STATUS_MSG } from "../../utils/StatusMsg";
import BlackBike from "../../Assests/Images/blackBike.png";
import RedBike from "../../Assests/Images/redBike.png";
import GrayBike from "../../Assests/Images/greyBike.png";
import YellowBike from "../../Assests/Images/yelloBike.png";
import L from "leaflet";
import { useNavigate, useParams } from "react-router-dom";
import Toster from "../../utils/Toster";
import DriverLocation from "../../Assests/Images/driverLocation1.png";

const Map = () => {
  const { unreadNotification } = useSelector((state) => state.user);

  const [currentLocation, setCurrentLocation] = useState([18.4861, -69.9312]);

  const [currentLocationGeo, setCurrentLocationGeo] = useState([
    18.4861, -69.9312,
  ]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userToken, mapData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const markerRef = useRef(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const { id } = useParams();

  //--------- function for calling my profile api--------

  const appMap = async () => {
    dispatch(getMapData(userToken)).then((response) => {
      const responsejson = response.payload;
      const ResponseCode = STATUS_MSG[responsejson && responsejson.data.code];

      if (
        responsejson &&
        responsejson.data &&
        responsejson.code === STATUS_CODES.SUCCESS
      ) {
        const data = responsejson?.data || [];
        let currentLocationForGeo = [];
        let currentLocationForVehicle = [];

        for (const item of data) {
          if (currentLocationForVehicle.length==0 && item?.latitude && item?.longitude) {
            currentLocationForVehicle = [
              item.latitude,
              item.longitude,
            ];
          }
          if (item?.geo_fence_latitude && item?.geo_fence_longitude) {
            currentLocationForGeo = [
              item.geo_fence_latitude,
              item.geo_fence_longitude,
            ];
            break;
          }
        }

        if (currentLocationForGeo?.length > 0) {
          setCurrentLocation(currentLocationForGeo);
        } else if (currentLocationForGeo.length==0 &&  currentLocationForVehicle?.length > 0) {
          setCurrentLocation(currentLocationForVehicle);
        } else if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const currentLatitude = position.coords.latitude;
              const currentLongitude = position.coords.longitude;

              setCurrentLocation([currentLatitude, currentLongitude]);
            },
            (error) => {
              // Handle error if geolocation fails (e.g., location access denied)

              setCurrentLocation([18.4861, -69.9312]);
            }
          );
        }
      } else if (
        responsejson &&
        responsejson.data &&
        responsejson.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      appMap();
    }, 5000);

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, []);

  useEffect(() => {
    appMap();
  }, []);

  //======= function for handle marker=========
  const getMarkerIcon = (vehicle) => {
    let iconUrl;
    switch (vehicle.colour_status) {
      case "black":
        iconUrl = BlackBike;
        break;
      case "red":
        iconUrl = RedBike;
        break;
      case "grey":
        iconUrl = GrayBike;
        break;
      case "yellow":
        iconUrl = YellowBike;
        break;
      default:
        iconUrl = BlackBike;
    }
    return L.icon({
      iconUrl: iconUrl,
      iconSize: [40, 40],
    });
  };

  // ==========function for handle marker click============
  const handleMarkerClick = (bike) => {
    setSelectedMarker(bike);
    markerRef.current?.openPopup();
  };

  const getMarker = (vehicle) => {
    if (vehicle?.latitude && vehicle?.longitude) {
      return (
        <Marker
          key={vehicle.vehicle_id}
          position={[vehicle?.latitude, vehicle?.longitude]}
          icon={getMarkerIcon(vehicle)}
          eventHandlers={{
            click: () => handleMarkerClick(vehicle),
          }}
        >
          {selectedMarker &&
            selectedMarker.vehicle_id === vehicle.vehicle_id && (
              <Popup closeButton={false}>
                <div className={styles.optionBike}>
                  <div
                    className={styles.bikeImage}
                    onClick={() =>
                      navigate(`/vehicle-detail/${vehicle.vehicle_id}`)
                    }
                  >
                    <img src={vehicle.vehicle_image} alt="bike" />{" "}
                  </div>

                  {/* <p>
    {t("PILOT_NAME")} : {vehicle.name}
  </p> */}
                  <p>
                    {t("PLATE_NUMBER")} : {vehicle.license_plate_number}
                  </p>
                  <p>
                    {t("BIKE_MODEL")} : {vehicle.vehicle_model}
                  </p>
                  <p>
                    {t("BIKE_COLOR")} : {vehicle.vehicle_colour}
                  </p>
                  <p>
                    {t("BIKE_YEAR")} : {vehicle.year}
                  </p>
                </div>
              </Popup>
            )}
        </Marker>
      );
    }
  };

  const getCenterIcon = (ceter) => {
    return L.icon({
      iconUrl: DriverLocation,
      iconSize: [20, 20],
    });
  };

  return (
    <>
      <div className={styles.mapWrapper}>
        <div className={styles.backGround}>
          <div className={styles.topItem}>
            <h5>{t("MAP_TITLE")}</h5>
          </div>
          <div className={styles.bellIcon}>
            {unreadNotification > 0 ? (
              <div className={styles.notificationTick}></div>
            ) : (
              ""
            )}
            <FaBell onClick={() => navigate("/notification")} />
          </div>
        </div>

        <div className="mapItem">
          <div style={{ height: "600px", width: "100%" }}>
            {currentLocation.length > 0 && (
              <MapContainer
                key={`${currentLocation[0]}-${currentLocation[1]}`}
                center={currentLocation}
                scrollWheelZoom={false}
                attributionControl={false}
                touchZoom={true}
                doubleClickZoom={true}
                zoom={16}
                style={{ height: "100%", width: "100%" }}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />

                {mapData &&
                  mapData.length > 0 &&
                  mapData?.map((vehicle) => (
                    <>
                      {vehicle.geo_fence_status === 1 &&
                        vehicle?.geo_fence_latitude &&
                        vehicle?.geo_fence_longitude && (
                          <Circle
                            center={[
                              vehicle?.geo_fence_latitude,
                              vehicle?.geo_fence_longitude,
                            ]}
                            radius={vehicle.distance * 1000}
                            fillColor="#FF2A00"
                            color="#FF2A00"
                            width="1"
                          />
                        )}

                      {vehicle?.geo_fence_latitude !== null &&
                      vehicle?.geo_fence_longitude !== null ? (
                        <Marker
                          position={[
                            vehicle?.geo_fence_latitude,
                            vehicle?.geo_fence_longitude,
                          ]}
                          icon={getCenterIcon()}
                        />
                      ) : (
                        ""
                      )}

                      {getMarker(vehicle)}
                      {/* {vehicle.latitude && vehicle.longitude?} */}
                    </>
                  ))}
              </MapContainer>
            )}
          </div>
        </div>
        <HomeFooterOption />
      </div>
    </>
  );
};

export default Map;
